<template>
   <div class="col-12" ref="estoque">
      <div class="card mb-1" :class="index % 2 == 1 ? 'invert' : ''">
         <div class="card-body p-12">
            <div class="row align-items-center">
               <div class="col-sm text-center text-sm-start cursor-pointer" @click="editar">
                  <h1 class="font-15 mb-0 limitador-1 text-uppercase">{{ estoque.nome }}</h1>
                  <p class="font-10 mb-1 limitador-1 text-secondary"><i class="far fa-hashtag font-9 me-1"></i>{{ estoque.id == null ? '?' : estoque.id }} </p>
                  <p class="font-12 mb-0 limitador-2">
                     <i class="far fa-store color-theme font-10 me-1"></i><strong class="subtitle me-1 d-none d-lg-inline"> Loja(s):</strong>
                     <span class="text-capitalize"> {{ estoque.lojas.filter(e => e.habilitado).map(e => e.nomeFantasia).length == 0 ? 'Sem lojas' : String(estoque.lojas.filter(e => e.habilitado).map(e => e.nomeFantasia)).replace(/,/g, ", ").toLowerCase() }}</span>
                  </p>
               </div>
               <div class="w-max-content d-flex align-items-center font-16 text-center mx-auto mx-md-0 mt-2 mt-md-0" v-if="dadosUsuario.usuarioPermissoes.includes('Configuração | Editar gerenciamento de estoques')">
                  <div class="btn-icone color-theme">
                     <span @click="editar"><i class="far fa-pen"></i><small>Editar</small></span>
                  </div>
                  <div class="btn-icone text-red">
                     <span @click="remover"><i class="far fa-trash"></i><small>Excluir</small></span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import Swal from 'sweetalert2'
import { mapState } from 'vuex'

export default {
   name: 'Estoque',
   props: ['estoque', 'index'],
   computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
         urlRest: state => state.urlRest
      })
   },
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      editar : function () {
         if (this.dadosUsuario.usuarioPermissoes.includes('Configuração | Editar gerenciamento de estoques')) {
            this.$emit('editar', this.estoque)
         }
      },
      remover : function () {
         Swal.fire({
            icon: 'warning',
            title: 'Excluir estoque?',
            text: 'O estoque inteiro será excluído permanentemente.',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: `Cancelar`

         }).then((result) => {
            if (result.isConfirmed) {
               this.$store.dispatch('alternarTelaCarregamento', true)

               this.$axios({
                  method: 'post',
                  url: this.urlRest +'configuracoes/deleteEstoque',
                  params: {
                     id: this.estoque.id
                  }
               }).then(() => {
                  this.$refs.estoque.style.display = 'none'

                  this.$toast.fire({
                     icon: 'success',
                     title: 'Estoque excluído!'
                  })
               }).catch((error) => {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        this.$store.dispatch('deslogar')

                        this.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else {
                        this.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  }
               }).finally(() => {
                  this.$store.dispatch('alternarTelaCarregamento', false)
               });
            }
         });
      }
   }
}

</script>